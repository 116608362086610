import React from "react";

const LoadingButton = ({ loading, children, disabled, ...props }) => {
  return (
    <button
      {...props}
      className={`bg-[#686968] py-3 rounded-[48px] text-white w-full md:w-1/2 hover:bg-gray-800 flex items-center justify-center ${
        loading || disabled
          ? "cursor-not-allowed bg-gray-400"
          : "hover:bg-gray-800 hover:cursor-pointer"
      }`}
      disabled={loading || disabled}
    >
      {loading ? (
        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
      ) : (
        children
      )}
    </button>
  );
};

export default LoadingButton;
