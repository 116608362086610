import React, { useState } from "react";
import landingImage from "../assets/images/landingImage.jpeg";
import SignIn from "../components/SignIn";
import SignUp from "../components/SignUp";

const LandingPage = () => {
  const [signIn, setSignIn] = useState(true);

  return (
    <div className="min-h-screen bg-gradient-to-r from-[#5EBAFB] via-[#EE44F7] to-[#5EBAFB] flex items-center justify-center p-4 sm:p-6 md:p-8 lg:p-12">
      <div className="grid grid-cols-1 lg:grid-cols-2 bg-white rounded-2xl shadow-2xl h-auto w-[80vw] md:h-[80vh]">
        <div className="relative h-64 md:h-auto p-4 md:p-8">
          <div className="absolute inset-0 p-4 md:p-2">
            <img
              src={landingImage}
              alt="landing"
              className="w-full h-full object-cover object-center rounded-2xl"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center p-6 md:p-2 lg:p-16">
          <div className="flex flex-col items-center">
            <h1 className="text-4xl sm:text-3xl md:text-4xl lg:text-6xl xl:text-8xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#5EBAFB] via-[#EE44F7] to-[#5EBAFB] my-4">
              Live2Fullest
            </h1>
            {signIn ? (
              <SignIn onSwitch={() => setSignIn(false)} />
            ) : (
              <SignUp onSwitch={() => setSignIn(true)} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
